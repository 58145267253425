import { ChakraProvider } from '@chakra-ui/react'
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Index from "./pages/Index"
import styled from 'styled-components'

function App() {
    const router = createBrowserRouter([
    {
        path: "/",
        element: <Index />,
    },
    ]);

    return (
            <ChakraProvider>
                <RouterProvider router={router} />
            </ChakraProvider>
    )
}

const ContentHeader = styled.div`
    text-align: center;

    h1 {
        font-size: 22px;
        font-weight: 500;
        margin: 0 0 20px 0;
    }
`

export {ContentHeader}
export default App
