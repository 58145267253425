import styled from 'styled-components'
import {ContentHeader} from '../App'
import Item from './Menu/Item'
import { useContext, useEffect, useRef, useState } from 'react'
import {MenuContext} from "../context/MenuContext";
import {MenuGroupsContext} from "../context/MenuGroupsContext";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
} from '@chakra-ui/react'
import Social from './Social';

const HEADER_HEIGHT = 60;

function Menu({page}) {
    const {menu} = useContext(MenuContext)
    const {menuGroups} = useContext(MenuGroupsContext)

    const findChildren = (item) => {
        return menu.filter(child => child.parent_id === item.id)
    }

    const [activeIndex, setActiveIndex] = useState(null);
    const accordionRefs = useRef([]);

    useEffect(() => {
        if (activeIndex !== null && accordionRefs.current[activeIndex]) {
            const element = accordionRefs.current[activeIndex];
            const rect = element.getBoundingClientRect();
            const isInView = rect.top >= HEADER_HEIGHT && rect.bottom <= window.innerHeight;

            if (!isInView) {
                window.scrollTo({
                    top: window.scrollY + rect.top - HEADER_HEIGHT,
                    behavior: 'smooth',
                });
            }
        }
    }, [activeIndex]);

    const handleAccordionChange = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };

    return (
        <Wrapper>
            <ContentHeader>
            </ContentHeader>
            <Accordion allowToggle mt={4} defaultIndex={[0]} reduceMotion index={activeIndex}>
                {menuGroups.map((group, index) =>
                    <AccordionItem key={index} ref={(el) => (accordionRefs.current[index] = el)}>
                    <h2>
                    <AccordionButton
                        background="#3F4041"
                        color="white"
                        _hover={{color: "#AE996F"}}
                        onClick={() => handleAccordionChange(index)}
                    >
                        <Box as="span" flex='1' textAlign='left'>
                        {group.name}
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4} px={0}>
                    {menu.map(item => item.parent_id === null &&
                        item.group_id === group.id &&
                        <Item
                            key={item.id}
                            item={item}
                            childs={findChildren(item)}
                        />

                    )}
                    </AccordionPanel>
                </AccordionItem>
                )}
            </Accordion>

            <Social />
        </Wrapper>
    );
}

const Wrapper = styled.div`
    flex: 1;
    flex-direction: column;
`;

const Items = styled.div`

`

const GroupTitleWrapper = styled.div`
    position: relative;
    height: 30px;
    // margin: 20px 0 10px 0;
    background: #fff;
`

const GroupTitle = styled.div`
    text-align: center;
    text-transform: uppercase;
    color: #AE996F;
    line-height: 25px;

    .sticky {
        position: fixed;
        top: 50px;
        left: 0;
        right: 0;
    }

    span {
        display: inline-block;
        background: #fff;
        padding: 0 10px;
        font-size: 10px;
        z-index: 1;
        position: relative;
    }

    &:after {
        content: "";
        position: absolute;
        display: block;
        height: 1px;
        background: #AE996F;
        left: 10px;
        right: 10px;
        top: 13px;
        opacity: 0.3;
    }
`

const Notice = styled.span`
    font-size: 14px;
    opacity: 0.6;
    display: block;
    margin-top: 5px;
`

export default Menu;
