import styled from 'styled-components'
import {ErrorBoundary} from 'react-error-boundary'
import App from "./App";

function Init() {
    function ErrorFallback({error, resetErrorBoundary}) {
        return (
            <Wrapper role="alert">
                <div>
                    <h1>Something went wrong</h1>
                    <p>
                        Dear Guest,
                        we are currently experiencing some technical problems,
                        please contract us via email <a href='mailto:hello@bohemehouse.lt'>hello@bohemehouse.lt</a>,
                        or call <a href='tel://+37066821771'>+370 (668) 21 771</a> if emergency.
                    </p>
                    <button onClick={resetErrorBoundary}>Try again</button>
                </div>
            </Wrapper>
        )
    }

    return (
        <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onReset={() => {
                // reset the state of your app so the error doesn't happen again
            }}
        >
            <App/>
        </ErrorBoundary>
    )
}

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    text-align: center;
    h1 {
        font-size: 20px;
    }
    & > div {
        padding: 20px;
        max-width: 400px;
    }
    
    p {
        line-height: 24px;
        margin-bottom: 30px;
    }
    
    a {
        white-space: nowrap
    }
`

export default Init
