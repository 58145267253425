import styled from "styled-components";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Menu from "../components/Menu";
import { useEffect, useState } from "react";
import { ErrorContext } from "../context/ErrorContext";
import {
    format,
} from "date-fns";
import Api from "../api/api";
import { MenuContext } from "../context/MenuContext";
import { MenuGroupsContext } from "../context/MenuGroupsContext";
import UrlError from "../components/UrlError";
import { ErrorBoundary } from "react-error-boundary";
import { TranslationContext } from "../context/TranslationContext";
import { Box, Button } from "@chakra-ui/react";
import { motion } from "framer-motion";
import Social from "../components/Social";

const { utcToZonedTime } = require("date-fns-tz");

const timeZone = "Europe/Vilnius";

function Index () {
    const [appLoading, setAppLoading] = useState(true);
    const [errors, setErrors] = useState({});
    const [translations, setTranslations] = useState({});
    const [page, setPage] = useState("time");
    const [menu, setMenu] = useState([]);
    const [menuGroups, setMenuGroups] = useState([]);
    const [urlError, setUrlError] = useState(false);
    const [today, setToday] = useState(format(new Date(), "y-MM-dd"));
    const [language, setLanguage] = useState(
        localStorage.getItem('boheme-menu-language') ?? 'LT',
    )
    const [isWaiterCalled, setIsWaiterCalled] = useState(false);
    const [loading, setLoading] = useState(true);
    const [showCallWaiter, setShowCallWaiter] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            const newDay = format(new Date(), "y-MM-dd");
            if (today !== newDay) {
                setToday(newDay);
            }
        }, 60 * 1000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        loadMenu(language)
        // Api.loadTranslations({ lang: language }).then((response) => {
        //     setTranslations(response);
        //     setAppLoading(false);
        // })

    }, [today]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [page]);

    useEffect(() => {
        const fetchData = async () => {
            Api.canCallWaiter({ lang: language }).then((response) => {
                setIsWaiterCalled(!response.canCallWaiter);
                setShowCallWaiter(response.showCallWaiter);
                setLoading(false);
            })
        };

        fetchData();
    }, []);

    const loadMenu = (langToLoad, orderId = null) => {
        setAppLoading(true);
        Api.getMenu(langToLoad, orderId).then((response) => {
            setMenu(response.items);
            setMenuGroups(response.groups);
            setAppLoading(false);
        });
    }
    const renderPage = () => {
        if (urlError) {
            return <UrlError />;
        }

        return <Menu page={page} />;
    };

    if (appLoading) {
        return (
            <Loading>
                <div>Loading...</div>
            </Loading>
        );
    }

    function ErrorFallback ({ error, resetErrorBoundary }) {
        return (
            <div role="alert">
                <p>Something went wrong:</p>
                <pre>{error.message}</pre>
                <button onClick={resetErrorBoundary}>Try again</button>
            </div>
        );
    }

    const handleChangeLanguage = () => {
        const langToSet = language === 'EN' ? 'LT' : 'EN'
        setLanguage(langToSet);
        loadMenu(langToSet)
        localStorage.setItem('boheme-menu-language', langToSet)
        // Api.loadTranslations({ lang: langToSet }).then((response) => {
        //     setTranslations(response);
        // })
    }

    let callWaiterText = "Call Waiter";
    let waiterCalledText = "Waiter Called";
    if (language === "LT") {
        callWaiterText = "Pakviesti padavėją";
        waiterCalledText = "Padavėjas pakviestas";
    }

    const MotionButton = motion(Button);

    const handleClick = async () => {
        setLoading(true);
        Api
            .callWaiter()
            .then((response) => {
                setIsWaiterCalled(response.waiterCalled);
            }).finally(() => {
                setLoading(false);
            });
    };

    return (
        <Layout>
            <ErrorContext.Provider value={{ errors, setErrors }}>
                <TranslationContext.Provider value={{ translations, setTranslations }}>
                    <MenuGroupsContext.Provider value={{ menuGroups }}>
                        <MenuContext.Provider value={{ menu, setMenu }}>
                            <ErrorBoundary
                                FallbackComponent={ErrorFallback}
                                onReset={() => {
                                    // reset the state of your app so the error doesn't happen again
                                }}
                            >
                                <Header page={page} setPage={setPage} language={language} changeLanguage={handleChangeLanguage} />
                                <Content>{renderPage()}</Content>
                                {showCallWaiter && (
                                <Box position="fixed" bottom={14} right="4" zIndex={99}>
                                    <MotionButton
                                        isLoading={loading}
                                        colorScheme={isWaiterCalled ? "red" : "green"}
                                        onClick={handleClick}
                                        isDisabled={isWaiterCalled}
                                        initial={{ scale: 1 }}
                                        whileHover={{ scale: 1.05 }}
                                        whileTap={{ scale: 0.95 }}
                                        transition={{ duration: 0.2 }}
                                        opacity="1 !important"
                                    >
                                        {isWaiterCalled ? waiterCalledText : callWaiterText}
                                    </MotionButton>
                                </Box>
                                )}
                                {!urlError && <Footer></Footer>}
                            </ErrorBoundary>
                        </MenuContext.Provider>
                    </MenuGroupsContext.Provider>
                </TranslationContext.Provider>
            </ErrorContext.Provider>
        </Layout>
    );
}

const Loading = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  text-align: center;
`;

const Layout = styled.div`
  display: flex;
  flex: 1;
  min-height: 100vh;
  width: 100%;
  padding: 100px 15px 150px 15px;
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  min-height: 100%;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;

  & > div {
    display: flex;
    width: 100%;
  }
`;

export default Index;
