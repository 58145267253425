import styled from 'styled-components'
import Icon from './Icon'
import {useContext, useEffect, useState} from 'react'
import Children from './Children'
import {MenuContext} from "../../context/MenuContext";

function Item({item, childs}) {
    const [folded, setFolded] = useState(true)
    const [childrenQuantity, setChildrenQuantity] = useState(0)
    const {menu} = useContext(MenuContext)

    useEffect(() => {
        setChildrenQuantity(findChildrenQuantity())
    }, [item.id])

    const findChildrenQuantity = () => {
        let quantity = 0

        return quantity
    }

    const toggleFold = () => {
        setFolded(!folded)
    }

    const renderFolding = () => {
        if (childrenQuantity > 0) {
          return <></>
        }
        return <Icon type={folded ? 'unfold' : 'fold'} onClick={toggleFold} />
    }

    const renderChildren = () => {
        if (folded && childrenQuantity === 0) {
            return <></>
        }

        return <Childs>
            {
                childs.map(item => <Children
                    item={item}
                    key={item.id}
                />)
            }
        </Childs>
    }

    return (
        <Wrapper selected={childrenQuantity > 0} unfolded={!folded || childrenQuantity > 0}>
            <div>
                <Left>
                    <div>{item.title} <span>{item.price}€</span></div>
                    <p>{item.description}</p>
                </Left>
                <Right>
                    {item.has_childrens ? renderFolding() : null}
                </Right>
            </div>
            {childs.length > 0 && renderChildren()}
        </Wrapper>
    );
}

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #F4F4F4;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #F4F4F4;
    border-color: ${props => props.selected && '#AE996F'};
    // background-color: ${props => props.unfolded ? '#E8E8E8' : '#F4F4F4'};

    ${props => props.selected && `
        box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.19);
        background-color: #E8E8E8;
    `}

    & > div {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
`;

const Left = styled.div`
    div {
        font-size: 15px;
        margin-bottom: 4px;

        span {
            font-size: 12px;
            color: #AE996F;
        }
    }

    p {
        margin: 0;
        font-size: 11px;
        opacity: 0.7;
    }
`
const Right = styled.div`
    display: flex;
    align-items: center;
    justify-content: right;
    flex-basis: 90px;
    flex-shrink: 0;
    flex-grow: 0;
`

const Quantity = styled.div`
    font-size: 18px;
    margin: 0 5px;
`

const Childs = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
    background-color: #E8E8E8;
    padding: 10px;

    & > div:last-child {
        margin-bottom: 0;
    }
`

export {Left, Right, Quantity}

export default Item;
