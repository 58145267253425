import styled from 'styled-components'
import Icon from './Icon'
import {useEffect, useState} from 'react'
import {Left, Quantity, Right} from './Item'

function Children({item}) {

    return (
        <Wrapper>
            <Left>
                <div>{item.title} <span>{item.price}€</span></div>
                <p>{item.description}</p>
            </Left>
            <Right>
            </Right>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
`;

export default Children;
