import styled from 'styled-components'

function Footer({children}) {
    return (
        <Wrapper>
            {children}
        </Wrapper>
    );
}

const Wrapper = styled.div`
    background: #3F4041;
    color: #FFFFFF;
    display: flex;
    position: fixed;
    flex-direction: column;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px 45px;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 12px;
    z-index: 10;
    
    p {
        margin: 0;
    }
`;

const Button = styled.button`
    border: 0;
    padding: 10px;
    background-color: #AE996F;
    color: #fff;
    border-radius: 10px;
    width: 100%;
    max-width: 300px;
    font-size: 18px;
    cursor: pointer;
    
    &:hover {
        opacity: 0.8;
    }
`
export {Button}
export default Footer;
