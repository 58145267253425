import logo from '../assets/logo.png'
import styled from 'styled-components'
import ltIcon from '../assets/icons/lt.svg'
import enIcon from '../assets/icons/gb.svg'

function Header ({ page, setPage, language, changeLanguage }) {

    const getLanguageLogo = () => {
        if (language !== 'LT') {
            return enIcon
        }
        return ltIcon
    }

    return (
        <Wrapper>
            <div>
                <Left>
                </Left>
                <Center>
                    <Logo onClick={() => setPage('menu')}><img src={logo} style={{ margin: "0 auto", width: "163px", maxWidth: "163px" }} alt='boheme' /></Logo>
                </Center>
                <Right>
                    <LanguageSelector onClick={() => changeLanguage()}>
                        <img width="28"src={getLanguageLogo()} alt="Language selector" />
                    </LanguageSelector>
                </Right>
            </div>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    background: #3F4041;
    color: #AE996F;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 15px;
    z-index: 10;

    & > div {
        display: flex;
        width: 100%;
        max-width: 800px;
        align-items: center;
        justify-content: space-between;
        margin: 0 auto;
    }
`;

const Back = styled.div`
    cursor: pointer;
    img {
        height: 30px;
    }
`

const Logo = styled.div`
    cursor: pointer;
    img {
        margin: 0 auto;
    }
`

const Room = styled.div`
    text-align: center;
    font-size: 10px;
    float: left;
`

const LanguageSelector = styled.div`
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    float: right;
    transition: color 0.3s ease-in;
    &:hover {
        color: #ffffff;
    }
`

const Number = styled.div`
    font-size: 20px;
`
const Left = styled.div`
    flex: 1;
`

const Center = styled.div`
    flex: 1;
    text-align: center;
`

const Right = styled.div`
    flex: 1;
`

export default Header;
