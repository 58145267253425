import styled from 'styled-components'

function UrlError() {
    return (
        <Wrapper>
            <div>Wrong url</div>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    flex: 1;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
`

export default UrlError;
