import axios from "axios";

export const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
});

axiosInstance.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axiosInstance.defaults.withCredentials = true;
axiosInstance.interceptors.response.use(response => response, error => {
    return new Promise((resolve, reject) => {
        try {
            let csrfRequestUrl = '/api/csrf'
            if (
                error
                && error.response
                && error.response.status === 419
                && error.config.url !== csrfRequestUrl
                && !error.config.retried
            ) {
                axiosInstance.get(csrfRequestUrl).then(response => {
                    if (response.data.token) {
                        axiosInstance.defaults.headers.common['X-CSRF-TOKEN'] = response.data.token
                        error.config.headers['X-CSRF-TOKEN'] = response.data.token
                        error.config.retried = true
                        axiosInstance.request(error.config).then(resolve).catch(reject)
                        return
                    }
                    reject(error)
                }).catch(() => reject(error))
            } else {
                reject(error)
            }
        } catch (e) {
            return reject(error)
        }
    })
});

const Api = {
   getMenu: (language, orderId) => {
        return new Promise(async resolve => {
            axiosInstance.get('menu', {
                params: {
                    language,
                    orderId,
                }
            }).then((response) => {
                resolve(response.data)
            }).catch((error) => {
                console.log("getMenu ERROR", error.response.data);
                resolve([])
            })
        })
    },
    loadTranslations: ({lang}) => {
        return new Promise(async resolve => {
            axiosInstance.get(`translations/${lang}`).then((response) => {
                resolve(response.data)
            }).catch((error) => {
                console.log("loadTranslations ERROR", error.response.data);
                resolve([])
            })
        })
    },
    canCallWaiter: () => {
        return new Promise(async resolve => {
            axiosInstance.get(`can-call-waiter`).then((response) => {
                resolve(response.data)
            }).catch((error) => {
                console.log("loadTranslations ERROR", error.response.data);
                resolve([])
            })
        })
    },
    callWaiter: () => {
        return new Promise(async resolve => {
            axiosInstance.post(`call-waiter`).then((response) => {
                resolve(response.data)
            }).catch((error) => {
                console.log("loadTranslations ERROR", error.response.data);
                resolve([])
            })
        })
    },
};

export default Api
