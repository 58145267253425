import styled from 'styled-components'
import icoPlus from '../../assets/icons/plus.svg'
import icoMinus from '../../assets/icons/minus.svg'
import icoUnfold from '../../assets/icons/unfold.svg'

function Icon({type, onClick}) {
    const resolveImage = () => {
        switch (type) {
            case 'plus':
                return icoPlus
            case 'minus':
                return icoMinus
            case 'unfold':
                return icoUnfold
            case 'fold':
                return icoUnfold
            default:
                return icoPlus
        }
    }

    return (
        <Wrapper onClick={onClick} type={type} >
            <img src={resolveImage()} alt={type}/>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #3F4041;
    border-radius: 50px;
    width: 30px;
    height: 30px;
    text-align: center;
    color: #fff;
    
    img {
        transform: ${props => props.type === 'fold' && 'rotate(180deg)'};
    }
`;

export default Icon;
